<template>
  <div>
    <CRow>
      <CCol md="12">
        <CCard>
          <CCardHeader>
            <strong>Job </strong>
          </CCardHeader>
         <CRow>
         <CCol md="8"> 

          <CCardBody>
            <CForm>
           
              <CInput
                label="Job Title *"
                v-model="job.jobTitle "
                horizontal
               
                :disabled="mode.viewMode"
                 :invalid-feedback="'Please Enter the Title'"
                   
                    @blur="$v.job.jobTitle.$touch()"
             />
<CTextarea
                label="Description  *"
                v-model="job.description"
                horizontal
                rows="6"
                :disabled="mode.viewMode"
                 :invalid-feedback="'Please Enter the Description'"
                    
                    @blur="$v.job.description.$touch()"
              >
              <!-- :is-valid="$v.job.description.$dirty? !$v.item.description.$error : null " -->
               <template #description>
                      <CRow>
                        <CCol md="12">
                          <small
                            style="float: right"
                            class="form-text text-muted"
                          >{{job.description.length}}</small>
                        </CCol>
                      </CRow>
                    </template>
              </CTextarea> 
            </CForm>
            
          </CCardBody>
          </CCol>
          
          </CRow>
          
          <CCardFooter align="right">
         <CButton
              v-if="mode.viewMode"
              class="mr-2"
              type="submit"
              size="sm"
              color="primary"
              @click="editMode()"
            >
              <CIcon name="cil-pencil" />Edit
            </CButton>

            <CButton
              v-if="!mode.viewMode"
              class="mr-2"
              type="submit"
              size="sm"
              color="success"
              @click="saveSpeciality()"
            >
              <CIcon name="cil-check-circle" />Save
            </CButton>

            <CButton class="mr-2" type="Cancel" size="sm" color="danger"  @click="cancel()">
              <CIcon name="cil-ban" />Cancel
            </CButton>
          </CCardFooter>
        </CCard>
      </CCol>
    </CRow>
  </div>
</template>

<script>
 import Vue from "vue";
 import datetime from 'vuejs-datetimepicker';
import {
  required,
  minLength,
  maxLength,
  url,
  requiredIf,
} from "vuelidate/lib/validators";
export default {
  name: "Job",
  data() {
    return {
      mode:{
        newMode:false,
        editMode:false,
        viewMode:false,
      },
     jobId:"",
      job:{
          jobId:"0",
        jobTitle:'',
       description:'',
      },
   
      horizontal: { label: "col-3", input: "col-9" },
    
   
     
    };
 },

 validations: {
   job: {
      
      jobTitle: {
           required,
       
      },
  description:{
           required,
       
      },
    
    },
  },
   created() {  
             if (this.$route.params.mode == "new") {
                      this.mode.newMode = true;
                        this.mode.viewMode = false;
                        this.mode.editMode = false;
                 }
             if(this.$route.params.mode =='view'){
                   this.mode.viewMode=true;
                    this.jobId = this.$route.params.jobId;
                    this.getUser();
            }
            if (this.$route.params.mode == "edit") {
                this.mode.editMode = true;
                this.jobId = this.$route.params.jobId;
                 this.getUser();
    }
        },
         components: { datetime },
  methods: {
    editMode(){
       this.mode.editMode=true;
        this.mode.viewMode=false;
    },
     cancel() {
      this.$router.push({ name: 'Jobs' })
    },
    saveSpeciality() {
      this.$v.job.$touch();
      if (this.$v.job.$invalid) {
        console.log("form is invalid", JSON.stringify(this.$v.job));
      }else{
     fetch(process.env.VUE_APP_API_HOST + "/web/faq/save", this.job
      ).then((post) => {
          this.$router.push({ name: "Job" });
        });
      }
     
    },
    validator(val) {
      return val ? val.length >= 4 : false;
    },
    
    
    getUser() {
     this.apiGetSecure(process.env.VUE_APP_API_HOST +"/web/secure/get/faq/"+this.groupId)
        .then((response) => response.json())
        .then( (data)  => (this.job = data) );
        
    },
    // getRoles() {
    // fetch(process.env.VUE_APP_API_HOST +"/web/secure/meta/roles")
    //     .then((response) => response.json())
    //     .then( (data)  => (this.roles = data) );
    // },
   
  },
  mounted() {
    //this. getRoles();
   
  }
};
</script>
